import Storage from "@/util/Storage";
import {delSpecialProduct, getSpecialProduct} from "@/Api/ShopAdmin";
import {Toast} from "vant";

// 特价商品列表
export function getSpecialList(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");

    return Promise.resolve(getSpecialProduct({userId,token},body).then(res=>{
        return res.data
    }))
}

// 删除特件商品
export function delSpecial(id:string|number = "") {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    Toast.loading({ duration:0,message:"删除中~~" })
    return Promise.resolve(delSpecialProduct({userId,token},{ id }).then(res=>{
        Toast.clear()
        return res.data
    }))
}
